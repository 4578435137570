.navbar {
    position: fixed;
    width: 100vw;
    background-color: var(--navbar-color);
    /* background-color: transparent; */
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
}

.dropdown-menu {
    background-color: var(--background-color);
}

.dropdown-item {
    color: white;
}

.dropdown-item:focus {
    background-color: var(--background-color);
}

.title {
    color: white;
    font-size: 72px;
    font-weight: 400;
    /* font-family: 'Dosis', sans-serif; */
}

.title-bold {
    font-weight: 700;
}

@media only screen and (max-width: 992px) {
    .main-text {
        margin-top: 4%;
        text-align: center;
    }
}

.hero {
    height: 100vh;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    /* background-image: linear-gradient(to bottom right,
        var(--background-color) 50%,
        transparent), url("img/bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    /* background-attachment: fixed; */
    /* position: absolute; */
    /* top: 0; */
}

.test {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    max-width: 100%;
}

.hero-img-top {
    height: 30vh;
    object-fit: cover;
    object-position: top;
    overflow: hidden;
}

.hero-img-right {
    object-fit: cover;
    object-position: top;
    overflow: hidden;
}

.nav-link {
    cursor: pointer;
    border-bottom: 2px solid transparent;
    color: white;
}

.nav-link.active {
    /* border-bottom: 2px solid var(--color-one); */
    font-weight: bold;
    /* border-bottom: 1px solid var(--color-one); */
    /* border-bottom: 2px solid; */
    /* border-image-source: var(--primary-gradient); */
    /* border-image-slice: 1; */
}

.target {
    margin-top: -60px;
}

.service-card {
    /* border: 1px solid rgb(36, 36, 36); */
    border: none;
    border-radius: 1em;
    /* border-radius: 0em; */
    /* background-color: var(--card-color); */
    /* background-image: radial-gradient(circle at 20% 0%,
      #242430 -10%,
      var(--background-color) 40%); */
    /* background-image: radial-gradient(
            circle at 50% 0%,
            #242430 -15%,
            transparent 40%
        ),
        linear-gradient(to bottom, var(--background-color) 55%, rgb(21, 21, 21)); */
    background-color: var(--navbar-color);
    color: white;
    overflow: hidden;
    /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5); */
}

.project-card {
    /* border: 1px solid rgb(36, 36, 36); */
    border-radius: 1em;
    /* border-radius: 0em; */
    /* background-color: var(--card-color); */
    /* background-image: linear-gradient(
        to bottom,
        var(--background-color) 55%,
        rgb(21, 21, 21)
    ); */
    border: none;
    background-color: var(--navbar-color);
    /* background-image: linear-gradient(135deg, var(--background-color) 70%, #1a1a1a); */
    color: white;
    overflow: hidden;
}

.project-card a {
    color: white;
}

.project-card .card-img-top {
    overflow: hidden;
    border-radius: 0;
}

.project-card .card-img-top img {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 14rem;
    transition: 0.3s;
    filter: brightness(80%);
}

.project-card:hover .card-img-top img {
    transform: scale(1.2);
    filter: brightness(100%);
}

.project-card:hover {
    /* background-color: var(--card-hover-color);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8); */
    /* background-image: linear-gradient(
        to bottom,
        var(--background-color) 55%,
        rgb(24, 24, 24)
    ); */
    /* background-image: linear-gradient(135deg, var(--background-color) 70%, #2a2a2a); */
    cursor: pointer;
}

.project-card .badge {
    /* background-color: var(--card-color); */
    background-color: var(--background-color);
    font-weight: normal;
    border-radius: 0.5em;
    /* border-radius: 0em; */
}

.icon-link {
    color: white;
    text-decoration: none;
}

.navbar-active {
    background-color: var(--background-color);
    /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5); */
    /* backdrop-filter:blur(16px); */
}

.form-control {
    background-color: var(--navbar-color);
    color: white;
    border: 1px solid var(--navbar-color);
    border-radius: 0.5em;
    /* border-radius: 0em; */
    /* background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.02)); */
}

.form-control:focus,
.form-control:active {
    background-color: var(--navbar-color);
    box-shadow: none !important;
    border: 1px solid var(--color-one);
    color: white;
}

.form-control.invalid {
    border: 1px solid #ff5c5c !important;
    /* border: 1px solid white !important; */
}

.warning {
    color: #ff5c5c;
    /* color: white; */
    font-size: smaller;
}

.form-control::placeholder {
    color: grey;
}

.btn-primary,
.btn-primary.disabled {
    /* background-color: var(--primary-gradient); */
    background: var(--color-one);
    /* background-image: var(--primary-gradient); */
    /* background-color: var(--color-one); */
    border: 0;
    /* border: 1px solid var(--color-one); */
    /* border-image-source: var(--primary-gradient); */
    /* border-image-slice: 1; */
    /* -webkit-border-image: var(--primary-gradient) 100% round; */
    border-radius: 0.5em;
    /* border-radius: 0em; */
    /* box-shadow: 0 0 40px -12px var(--color-one); */
}

.btn-primary:hover,
.btn-primary:focus {
    background: var(--color-two);
    box-shadow: none !important;
}

.btn-primary:active {
    background: var(--color-one) !important;
}

.btn-outline-primary {
    color: white;
    /* border: 1px solid rgb(36, 36, 36); */
    border: 1px solid white;
    border-radius: 0.5em;
    /* border-radius: 0em; */
    /* background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.05)); */
}

.btn-outline-primary:active {
    /* border: 1px solid rgb(36, 36, 36) !important;
    background: none !important;
    background-image: none !important; */
    color: white !important;
    background: none !important;
    border: 1px solid white !important;
}

.btn-outline-primary:hover {
    /* color: white;
    border: 1px solid rgb(36, 36, 36); */
    /* border-radius: 0.75em; */
    /* border-radius: 0em;
    background: none;
    background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.1)); */
    background-color: white;
    border: 1px solid white;
    color: var(--background-color);
}

/* .divider {
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right,
      var(--background-color), var(--color-one), var(--background-color));
} */

.session-title {
    /* font-family: 'Dosis', sans-serif; */
    font-weight: bold;
    /*background: -webkit-linear-gradient(white, #9a9cc2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
    /* padding-bottom: 16px; */
    /* border-bottom: 1px solid var(--color-one); */
}

.main-text {
    font-size: 20px;
}

.navbar-toggler {
    border: none;
}

.timeline {
    border-left: 1px solid hsl(0, 0%, 90%);
    position: relative;
    list-style: none;
}

.timeline .event {
    position: relative;
}

.timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .timeline-icon {
    position: absolute;
    left: -48px;
    background-color: white;
    color: var(--background-color);
    border-radius: 50%;
    height: 31px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
}

textarea {
    resize: none;
}

.chip {
    background-color: var(--navbar-color);
    color: white;
    /* border: 1px solid white; */
    border-radius: 0.5rem;
    /* border-radius: 0em; */
    padding: 0.25rem 1rem;
    margin: 0.2rem;
    cursor: pointer;
    font-size: 14px;
}

.chip:hover {
    /* background-color: var(--card-hover-color); */
    /* color: white; */
    color: var(--background-color);
    background-color: white;
}

.chip-active,
.chip-active:hover {
    background-color: white;
    color: var(--background-color);
    /* color: white; */
    /* border: 1px solid rgb(36, 36, 36); */
    /* background-image: linear-gradient(rgba(255, 255, 255, 0.05), transparent); */
    border-radius: 0.5em;
    /* border-radius: 0em; */
}

.logo {
    /* font-family: 'Dosis', sans-serif; */
    font-size: larger;
    color: var(--background-color);
    /* color: white; */
    background-color: white;
    border-radius: 0.25em;
}

.skill {
    /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5); */
    border-radius: 0.5em;
    margin-right: 4px;
    margin-bottom: 4px;
}

.skill-icon {
    /* background: var(--white-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
    /* color: #45B649; */
    /* color: var(--color-one); */
}

.service-icon {
}

.reveal {
    position: relative;
    transform: translateY(20px);
    opacity: 0;
    transition: 1.5s all ease;
}

.reveal.active {
    transform: translateY(0);
    opacity: 1;
}

.appear {
    animation-duration: 1.5s;
    animation-name: fadeIn;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.spinner-border {
    /* color: var(--color-one); */
    color: var(--color-one);
}

.navbar-toggler:active,
.navbar-toggler:focus {
    box-shadow: none;
}

.invisible {
    visibility: hidden;
}

.my-button {
    width: 250px;
}

@media (max-width: 768px) {
    .my-button {
        width: 100%;
    }
}