:root {
  /* --primary-color: #44c4a1; */
  /* --color-one: #7bdd9a; THIS WAS GOOD, DON'T REMOVE IT */
  /* --color-one: #93F9B9; */
  /* --color-two: #1D976C; */
  --color-one: #2859fe;
  --color-two: #1d4ae0;
  /* --background-color: #1c1e20; */
  --background-color: #0a0a0a;
  --navbar-color: #080808;
}

html,
body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Heebo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  background: var(--background-color);
  /* background-image:
  radial-gradient(
    circle at center -60%,
    var(--color-two),
    transparent 50%
      ); */
  background-repeat: no-repeat;
  color: #fff;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  font-size: 18px;
}
